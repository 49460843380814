
.containerborder {
    display: flex;
    flex-flow: column;
    font-size: 1rem;
    /* border: 2px solid #ccc; */
    max-width: 1120px;
    width: 90%;
    margin: 6rem auto;
    padding-bottom: 2rem;
    align-items: center;
    
}

.containerborder > h5 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    padding: 5px;
    font-size: 0.9rem;
    
}

.contactheader {
    font-family: 'Montserrat',sans-serif;
    font-weight: 400;
    font-size: 1.5rem;
    color: #262626;
    margin-bottom: 8px;
}

.contactform {
    display: flex;
    flex-flow: column;
    font-family: 'Montserrat',sans-serif;
    width: 50%;
    margin: 0 auto;
    row-gap: 10px;
    

}


@media screen and (max-width: 540px) {
    .contactform {
        width: 90%;
        margin-top: 3rem;
    } 
}

.contactform > * {
    font-family: 'Montserrat',sans-serif;
    color: #262626;
}

.inputbutton {
    background-color: #989898;
    color: white;
}

.messagebox {
    max-width: 100%;
    min-width: 100%;
}

.eyevine {
    text-decoration: none;
    color: #262626;
    text-align: center;

}

a:hover {
    color: orange;
  }
  