
.Carousel {
    padding: 0 6vw;
    height: 85vh;
    display: fixed;
    align-items: center;
    justify-content: center;
    
    margin: 0px auto;
    
    /* object-fit: contain; */
    
    


    } 
    
    .d-block {
        width: auto;
        height: 85vh;
        margin: auto;
        max-height: 85vh;
        max-width: 100%;
        
        
        /* // center image in div */
        
    }

    .d-block-tall {
        width: auto;
        margin: auto;
        
        max-height: 85vh;
        max-width: 100%;
        display: flex;
        align-items: center;
        
        
    }




    @media screen and (max-width: 769px) {
        .d-block {
            width: auto;
            margin: auto;
            max-height: 85vh;
            max-width: 100%;
            /* // center image in div */
            padding : 27vh 0;
        }

        .d-block-tall {
            width: auto;
            margin: auto;
            max-height: 85vh;
            max-width: 100%;
            padding: 10vh 0;
            display: flex;
            align-items: center;
            
        }
    }




    




    
 

  
    


   .carousel-control-prev-icon,
   .carousel-control-next-icon {
    filter: invert(50%)
   }

   .carousel-indicators 
   {
    visibility:hidden;
   }

 


   .carousel-item {
    
    transition: opacity 0.6s ease-in-out !important;
    

  }
  
  /* .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: opacity 0s 0.6s ease-in-out !important;
  } */

  
 